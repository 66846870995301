import React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '~components/layouts/Default';
import PageHeader from '~components/common/PageHeader';
import Container from '~components/common/Container';
import GridTwo from '~components/common/GridTwo';
import H1 from '~components/typography/H1';
import H2 from '~components/typography/H2';
import P from '~components/typography/P';
import ImageVideo from '~components/common/ImageVideo';
import Divider from '~components/common/Divider';

export default function Page({ data, location }) {
  return (
    <Layout location={location} title="Die Entspannungshelden stellen sich vor" desc="Hier erfährst du, wie wir es geschafft haben entspannter zu werden" active="Über uns">
      <PageHeader title="Über uns" breadcrumb={[{ title: 'Über uns', link: '/ueber-uns/', current: true }]} />
      <Container noMargin className="relative">
        <div className="mb-8 text-center">
          <H1>Das sind deine Entspannungsexperten.</H1>
        </div>
        <GridTwo itemsStart>
          <div className="space-y-3 rounded-xl bg-white p-6">
            <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1647337629/kunden/entspannungshelden/johannes_g0vpuk.mp4" image={data.videCoverJohannes.childImageSharp} alt="Johannes Förster" smallIcon />
            <H2>Johannes Förster</H2>
            <P>
              Johannes ist als Entspannungspädagoge bestens mit Entspannungsverfahren vertraut. Seit 2016 ist er für Firmen im Betrieblichen Gesundheitsmanagement tätig und berät diese zu Gesundheitsthemen. Die Entspannungshelden sind für
              ihn eine tolle Möglichkeit, die Verfahren auch an Privatpersonen weiterzugeben.
            </P>
          </div>
          <div className="space-y-3 rounded-xl bg-white p-6">
            <ImageVideo video="https://res.cloudinary.com/lautenschlager/video/upload/v1647337624/kunden/entspannungshelden/fabian_u92axu.mp4" image={data.videCoverFabian.childImageSharp} alt="Fabian Feder" smallIcon />
            <H2>Fabian Feder</H2>
            <P>
              Fabian lernte vor 2 Jahren als Anfänger im Entspannungsbereich die wunderbare Wirkung von verschiedenen Entspannungsverfahren kennen. Dies motivierte ihn weitere Menschen davon zu begeistern. Aufgrund dessen lernte er Johannes
              kennen, was der Startschuss für die Entspannungshelden war.
            </P>
          </div>
        </GridTwo>
      </Container>

      <Container>
        <Divider />
        <div className="my-8 text-center">
          <P className="mb-3">Unsere Geschichte</P>
          <H2 className="font-display">So haben wir uns entspannt</H2>
        </div>
        <GridTwo itemsCenter>
          <div>
            <StaticImage src="../media/johannes-daumen.jpg" alt="Johannes mit Daumen hoch" className="rounded-xl" />
          </div>
          <div className="max-w-lg">
            <P>
              Fabian und Johannes sind davon überzeugt, dass sich Entspannungsverfahren zusammen mit der heutigen Digitalisierung perfekt ergänzen und sich daraus eine wunderbare Kombination ergibt. Obwohl beide aus unterschiedlichen
              Berufszweigen kommen, überschneidet sich die Expertise. So entstanden aus Fabian und Johannes die Entspannungshelden. Unser Ziel ist es, so vielen Menschen wie möglich auf die einfachste Weise den Zugang zu qualitativ
              hochwertigen Entspannungsverfahren zu ermöglichen.
            </P>
          </div>
        </GridTwo>
      </Container>
    </Layout>
  );
}

export const query = graphql`
  {
    videCoverJohannes: file(relativePath: { eq: "johannes.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    videCoverFabian: file(relativePath: { eq: "fabian.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
